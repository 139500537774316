import React from 'react';
import { ButtonInline } from '@bbc-account/id-components';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { Container } from './editUserIdentifier.style';

export const EditUserIdentifier = ({ onClick, userIdentifier }) => {
    return (
        <Container>
            {userIdentifier}
            <ButtonInline onClick={onClick}>
                <FormattedMessage id="emailFirst.editEmail.title" />
            </ButtonInline>
        </Container>
    );
};

EditUserIdentifier.propTypes = {
    userIdentifier: PropTypes.string.isRequired,
    onClick: PropTypes.func.isRequired,
};
